import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Checkbox,
  ListItemText,
  ListSubheader,
  CircularProgress
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Helper to format a date into M/YY format (for monthly granularity)
const monthFormat = (dateStr) => {
  const date = new Date(dateStr);
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().substr(-2);
  return `${month}/${year}`;
};

// Helper to format a date into Week format (for weekly granularity)
// Returns the Monday's date in "M/D/YY" format.
const weekFormat = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDay(); // 0=Sunday, 1=Monday, ...
  const diff = day === 0 ? -6 : 1 - day; // shift so that Monday is the first day
  const monday = new Date(date);
  monday.setDate(date.getDate() + diff);
  const m = monday.getMonth() + 1;
  const d = monday.getDate();
  const y = monday.getFullYear().toString().substr(-2);
  return `${m}/${d}/${y}`;
};

const AdminDashboard = () => {
  // Full data from API
  const [leadStats, setLeadStats] = useState(null);
  // Dropdown arrays from API
  const [uniqueOwners, setUniqueOwners] = useState([]);
  const [uniqueLeadSources, setUniqueLeadSources] = useState([]);
  const [uniqueStates, setUniqueStates] = useState([]);
  // Filter selections
  const [filterLeadSource, setFilterLeadSource] = useState([]);
  const [filterOwner, setFilterOwner] = useState('');
  const [filterState, setFilterState] = useState('');
  // Date range filters (using Date objects; initial value is null)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Granularity: "monthly" or "weekly"
  const [granularity, setGranularity] = useState('monthly');
  // Chart and totals state
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [totals, setTotals] = useState({
    leads: 0,
    projects: 0,
    serviceProjects: 0,
    solarProjects: 0,
    segmentCost: 0,
    cost: 0,
    projectValues: 0,
    conversionRate: 0,
    segLeads: 0,
    segConversionRate: 0,
    sitRate: 0,
    combinedCost: 0
  });

  // Determine grouping function based on granularity
  const groupBy = granularity === 'weekly' ? weekFormat : monthFormat;

  // On mount, fetch full data from endpoint with includeFilters=true.
  useEffect(() => {

    setShowLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/lead/stats?includeFilters=true`)
      .then((response) => {
        const data = response.data;
        setLeadStats(data);
        setUniqueOwners(data.uniqueOwners.sort());
        setUniqueLeadSources(data.uniqueLeadSources.sort());
        setUniqueStates(data.uniqueStates.sort());
        applyFilters(data);
      })
      .catch((error) => {
        console.error('Error fetching lead stats:', error);
      })
      .finally(() => {
        setShowLoading(false); // Hide loading icon when request completes
      });

  }, []);

  

  // Reapply filters whenever a filter selection or date range/granularity changes.
  useEffect(() => {
    if (leadStats) {
      applyFilters(leadStats);
    }
  }, [filterLeadSource, filterOwner, filterState, startDate, endDate, granularity]);

  // applyFilters aggregates the data from leadStats based on the selected filters.
  const applyFilters = (data) => {
    // Filter combined leads and projects based on current filters.
    let filteredLeads = data.combinedLeads;
    let filteredProjects = data.projects;
    let filteredSolarLeads = [];
    if (filterLeadSource.length > 0) {
      filteredLeads = filteredLeads.filter(item => filterLeadSource.includes(item.leadSource));
      filteredProjects = filteredProjects.filter(item => filterLeadSource.includes(item.leadSource));
    }
    if (filterOwner) {
      filteredLeads = filteredLeads.filter(item => item.owner === filterOwner);
      filteredProjects = filteredProjects.filter(item => item.owner === filterOwner);
    }
    if (filterState) {
      filteredLeads = filteredLeads.filter(item => item.state === filterState);
      filteredProjects = filteredProjects.filter(item => item.state === filterState);
    }
    // For Solar Reviews, if no lead source is selected or if Solar Reviews is selected, process solarInsideLeads.
    if ((filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) && data.solarInsideLeads) {
      filteredSolarLeads = data.solarInsideLeads;
      if (filterState) {
        filteredSolarLeads = filteredSolarLeads.filter(item => item.state === filterState);
      }
    }
    // Apply date range filtering if dates are selected.
    if (startDate) {
      filteredLeads = filteredLeads.filter(item => new Date(item.created) >= startDate);
      filteredProjects = filteredProjects.filter(item => new Date(item.created) >= startDate);
      if (filteredSolarLeads.length > 0) {
        filteredSolarLeads = filteredSolarLeads.filter(item => new Date(item.created) >= startDate);
      }
    }
    if (endDate) {
      filteredLeads = filteredLeads.filter(item => new Date(item.created) <= endDate);
      filteredProjects = filteredProjects.filter(item => new Date(item.created) <= endDate);
      if (filteredSolarLeads.length > 0) {
        filteredSolarLeads = filteredSolarLeads.filter(item => new Date(item.created) <= endDate);
      }
    }

    // Group filtered leads by period.
    const leadsGroup = {};
    filteredLeads.forEach(item => {
      if (item.created) {
        const period = groupBy(item.created);
        leadsGroup[period] = (leadsGroup[period] || 0) + 1;
      }
    });

    // Group projects by period, splitting into solar and service.
    const solarProjectsGroup = {};
    const serviceProjectsGroup = {};
    filteredProjects.forEach(item => {
      if (item.created) {
        const period = groupBy(item.created);
        if (item.stage && item.stage.toLowerCase().includes('service')) {
          serviceProjectsGroup[period] = (serviceProjectsGroup[period] || 0) + 1;
        } else {
          solarProjectsGroup[period] = (solarProjectsGroup[period] || 0) + 1;
        }
      }
    });
    // Overall projects group (for conversion calculation).
    const projectsGroup = {};
    Object.keys(solarProjectsGroup).forEach(period => {
      projectsGroup[period] = (projectsGroup[period] || 0) + solarProjectsGroup[period];
    });
    Object.keys(serviceProjectsGroup).forEach(period => {
      projectsGroup[period] = (projectsGroup[period] || 0) + serviceProjectsGroup[period];
    });

    // Aggregate project values.
    const projectValuesGroup = {};
    filteredProjects.forEach(item => {
      if (item.created) {
        const period = groupBy(item.created);
        projectValuesGroup[period] = (projectValuesGroup[period] || 0) + Number(item.projectValue || 0);
      }
    });

    // For Solar Reviews, group segment data.
    const segmentGroup = {};
    if (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) {
      filteredSolarLeads.forEach(item => {
        if (item.created) {
          const period = groupBy(item.created);
          segmentGroup[period] = segmentGroup[period] || { segLeads: 0, segCost: 0 };
          segmentGroup[period].segLeads += 1;
          segmentGroup[period].segCost += 125; // fixed cost per segment lead.
        }
      });
    }

    // Group ramp transactions (lead cost) by period.
    let rampTransactionsFlat = [];
    if (data.rampTransactions) {
      if (filterLeadSource.length > 0) {
        // Process transactions for each selected lead source.
        filterLeadSource.forEach(ls => {
          let source;
          if (ls === "Google LSA") source = "Google Ads";
          else if (ls === "Solar Reviews") source = "SolarReviews";
          else if (ls === "Enphase Leads") source = "Solar Lead Factory";
          else if (ls === "Solar Inside Sales") source = "SolarInside";
          if (source && data.rampTransactions[source]) {
            rampTransactionsFlat = rampTransactionsFlat.concat(data.rampTransactions[source]);
          }
        });
      } else {
        // No lead source selected: include all.
        Object.values(data.rampTransactions).forEach(arr => {
          rampTransactionsFlat = rampTransactionsFlat.concat(arr);
        });
      }
    }

    if (startDate) {
      rampTransactionsFlat = rampTransactionsFlat.filter(tx => new Date(tx.date) >= startDate);
    }
    if (endDate) {
      rampTransactionsFlat = rampTransactionsFlat.filter(tx => new Date(tx.date) <= endDate);
    }
    

    const costGroup = {};
    rampTransactionsFlat.forEach(tx => {
      if (tx.date) {
        const period = groupBy(tx.date);
        costGroup[period] = (costGroup[period] || 0) + Number(tx.price || 0);
      }
    });

    // *** NEW: Compute segment cost from rampTransactions for SolarInside ***
    let segmentCostGroup = {};
    if ((filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) &&
        data.rampTransactions &&
        data.rampTransactions["SolarInside"]) {
      let rampSegmentTx = data.rampTransactions["SolarInside"];
      // Apply date range filters if provided.
      if (startDate) {
        rampSegmentTx = rampSegmentTx.filter(tx => new Date(tx.date) >= startDate);
      }
      if (endDate) {
        rampSegmentTx = rampSegmentTx.filter(tx => new Date(tx.date) <= endDate);
      }
      rampSegmentTx.forEach(tx => {
        if (tx.date) {
          const period = groupBy(tx.date);
          segmentCostGroup[period] = (segmentCostGroup[period] || 0) + Number(tx.price || 0);
        }
      });
    }

    // Get all periods.
    const allPeriodsSet = new Set([
      ...Object.keys(leadsGroup),
      ...Object.keys(projectsGroup),
      ...Object.keys(costGroup),
      ...((filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) ? Object.keys(segmentGroup) : []),
    ]);
    let periods = Array.from(allPeriodsSet);

    // Sort periods chronologically.
    if (granularity === 'weekly') {
      periods.sort((a, b) => {
        const [mA, dA, yA] = a.split('/');
        const [mB, dB, yB] = b.split('/');
        const dateA = new Date(`20${yA}-${mA.padStart(2, '0')}-${dA.padStart(2, '0')}`);
        const dateB = new Date(`20${yB}-${mB.padStart(2, '0')}-${dB.padStart(2, '0')}`);
        return dateA - dateB;
      });
    } else {
      periods.sort((a, b) => {
        const [mA, yA] = a.split('/');
        const [mB, yB] = b.split('/');
        const dateA = new Date(`20${yA}-${mA.padStart(2, '0')}-01`);
        const dateB = new Date(`20${yB}-${mB.padStart(2, '0')}-01`);
        return dateA - dateB;
      });
    }

    // Build chart arrays.
    const leadsData = [];
    const nonSegmentLeadsData = [];
    const segmentLeadsData = [];
    const projectsData = [];
    const costData = [];
    const projectValuesData = [];
    const conversionRates = []; // Conversion Rate: (projects / leads) * 100
    const segConversionRates = []; // For Solar Reviews: (segment leads / projects) * 100
    const sitRates = []; // For Solar Reviews: (segment leads / leads) * 100

    periods.forEach(period => {
      const totalLeads = leadsGroup[period] || 0;
      const projectsCount = projectsGroup[period] || 0;
      const costSum = costGroup[period] || 0;
      const projValueSum = projectValuesGroup[period] || 0;
      let segLeads = 0;
      if (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) {
        segLeads = segmentGroup[period] ? segmentGroup[period].segLeads : 0;
      }
      let nonSegLeads = (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) ? Math.max(0, totalLeads - segLeads) : totalLeads;
      if (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) {
        nonSegmentLeadsData.push(nonSegLeads);
        segmentLeadsData.push(segLeads);
      } else {
        leadsData.push(totalLeads);
      }
      projectsData.push(projectsCount);
      costData.push(costSum);
      projectValuesData.push(projValueSum);

      const convRate = totalLeads > 0 ? ((projectsCount / totalLeads) * 100).toFixed(1) : '0';
      conversionRates.push(`Conv: ${convRate}%`);
      if (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) {
        const segConvRate = projectsCount > 0 ? ((segLeads / projectsCount) * 100).toFixed(1) : '0';
        const sitRate = totalLeads > 0 ? ((segLeads / totalLeads) * 100).toFixed(1) : '0';
        segConversionRates.push(`Seg Conv: ${segConvRate}%`);
        sitRates.push(`Sit: ${sitRate}%`);
      }
    });

    // Filter periods within the selected date range
    if (startDate || endDate) {
        periods = periods.filter(period => {
        let periodDate;
        
        if (granularity === 'weekly') {
            const [m, d, y] = period.split('/');
            periodDate = new Date(`20${y}-${m.padStart(2, '0')}-${d.padStart(2, '0')}`);
        } else {
            const [m, y] = period.split('/');
            periodDate = new Date(`20${y}-${m.padStart(2, '0')}-01`);
        }
    
        return (!startDate || periodDate >= startDate) && (!endDate || periodDate <= endDate);
        });
    }

    // Build datasets.
    const datasets = [];
    if (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) {
      datasets.push({
        label: 'Leads',
        data: periods.map(period => {
          const totalLeads = leadsGroup[period] || 0;
          const segLeads = segmentGroup[period] ? segmentGroup[period].segLeads : 0;
          return Math.max(0, totalLeads - segLeads);
        }),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        stack: 'leads',
      });
      datasets.push({
        label: 'Solar Inside Leads',
        data: periods.map(period => segmentGroup[period] ? segmentGroup[period].segLeads : 0),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        stack: 'leads',
      });
    } else {
      datasets.push({
        label: 'Leads',
        data: periods.map(period => leadsGroup[period] || 0),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      });
    }
    datasets.push({
      label: 'Solar Projects',
      data: periods.map(period => solarProjectsGroup[period] || 0),
      backgroundColor: 'rgba(153, 102, 255, 0.6)',
      stack: 'projects',
    });
    datasets.push({
      label: 'Service Projects',
      data: periods.map(period => serviceProjectsGroup[period] || 0),
      backgroundColor: 'rgba(255, 206, 86, 0.6)',
      stack: 'projects',
    });
    // Build lead and segment cost arrays.
    const leadCost = periods.map(period => costGroup[period] || 0);
    const segmentCost = periods.map(period =>
      (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews'))
        ? (segmentCostGroup[period] || 0)
        : 0
    );   
    datasets.push({
      label: 'Lead Cost',
      data: leadCost,
      type: 'line',
      borderColor: 'rgba(255, 99, 132, 1)',
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      yAxisID: 'cost2',
    });
    datasets.push({
      label: 'Solar Inside Cost',
      data: segmentCost,
      type: 'line',
      borderColor: 'rgba(61, 8, 19, 1)',
      backgroundColor: 'rgba(61, 8, 19, 0.5)',
      yAxisID: 'cost2',
    });
    datasets.push({
      label: 'Project Values',
      data: periods.map(period => projectValuesGroup[period] || 0),
      type: 'line',
      borderColor: 'rgba(255, 159, 64, 1)',
      backgroundColor: 'rgba(255, 159, 64, 0.2)',
      yAxisID: 'projectValue',
    });

    const labels = periods.map((period, idx) => {
        let totalLeads = leadsGroup[period] || 0;
        let projectsCount = (solarProjectsGroup[period] || 0) + (serviceProjectsGroup[period] || 0);
        const convRate = totalLeads > 0 ? ((projectsCount / totalLeads) * 100).toFixed(1) : '0';
    
        // Construct an array to make it multiline
        const labelArr = [period, `Conv: ${convRate}%`];
    
        if (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')) {
          const segLeads = segmentGroup[period] ? segmentGroup[period].segLeads : 0;
          const segConvRate = projectsCount > 0 ? ((segLeads / projectsCount) * 100).toFixed(1) : '0';
          const sitRate = totalLeads > 0 ? ((segLeads / totalLeads) * 100).toFixed(1) : '0';
          labelArr.push(`Seg: ${segConvRate}%`, `Sit: ${sitRate}%`);
        }
    
        return labelArr;
    });

    const newChartData = {
      labels,
      datasets,
    };

    // Build chart options with dynamic scales.
    const newChartOptions = {
      responsive: true,
      plugins: {
        legend: { position: 'top' },
        title: {
          display: true,
          text:
            'Monthly/Weekly KPIs: Leads (Stacked for Solar Reviews), Projects, & Cost Metrics',
        },
      },
      scales: {
        y: {
          type: 'linear',
          position: 'left',
          stacked: (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews')),
          title: { display: true, text: 'Count (Leads / Projects)' },
        },
        cost2: {
          type: 'linear',
          position: 'right',
          title: { display: leadCost.some(val => val > 0) || segmentCost.some(val => val > 0), text: 'Cost ($)' },
          grid: { drawOnChartArea: false },
        },
        projectValue: {
          type: 'linear',
          position: 'right',
          title: { display: Object.values(projectValuesGroup).reduce((a, b) => a + b, 0) > 0, text: 'Project Values ($)' },
          grid: { drawOnChartArea: false },
        },
      },
    };

    setChartOptions(newChartOptions);
    setChartData(newChartData);

    // Compute totals.
    const totalLeads = Object.values(leadsGroup).reduce((acc, val) => acc + val, 0);
    const totalProjects = Object.values(projectsGroup).reduce((acc, val) => acc + val, 0);
    const totalSolarProjects = Object.values(solarProjectsGroup).reduce((acc, val) => acc + val, 0);
    const totalServiceProjects = Object.values(serviceProjectsGroup).reduce((acc, val) => acc + val, 0);
    const totalLeadCost = Object.values(costGroup).reduce((acc, val) => acc + val, 0);
    const totalSegmentCost =
      (filterLeadSource.length === 0 || filterLeadSource.includes('Solar Reviews'))
        ? Object.values(segmentCostGroup).reduce((acc, val) => acc + val, 0)
        : 0;
    const combinedCost = totalLeadCost + totalSegmentCost;
    const totalProjectValues = Object.values(projectValuesGroup).reduce((acc, val) => acc + val, 0);
    const totalConversionRate = (totalProjects / totalLeads) * 100;

    setTotals({
      leads: totalLeads,
      serviceProjects: totalServiceProjects,
      solarProjects: totalSolarProjects,
      projects: totalProjects,
      cost: totalLeadCost,
      segmentCost: totalSegmentCost,
      projectValues: totalProjectValues,
      conversionRate: totalConversionRate,
      combinedCost: combinedCost,
    });
  };

  const paidLeads = ["Google LSA", "Enphase Leads", "Solar Reviews"];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'leadSource') {
      let newVal = value;
      if (typeof newVal === 'string') {
        newVal = newVal.split(",");
      }
      if (newVal.includes("Paid Leads")) {
        const newSelection = [...new Set([...filterLeadSource, ...paidLeads])];
        setFilterLeadSource(newSelection);
      } else if (filterLeadSource.includes("Paid Leads")) {
        setFilterLeadSource(newVal.filter(v => !paidLeads.includes(v)));
      } else {
        setFilterLeadSource(newVal);
      }
    } else if (name === 'owner') {
      setFilterOwner(value);
    } else if (name === 'state') {
      setFilterState(value);
    }
  };

  const resetFilters = () => {
    setFilterLeadSource([]);
    setFilterOwner('');
    setFilterState('');
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Filters Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                  <InputLabel id="lead-source-label">Lead Source</InputLabel>
                  <Select
                    labelId="lead-source-label"
                    name="leadSource"
                    multiple
                    value={filterLeadSource}
                    label="Lead Source"
                    onChange={handleFilterChange}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {/* Group: Paid Leads */}
                    <ListSubheader>Paid Leads</ListSubheader>
                    <MenuItem value="Paid Leads">
                      <Checkbox checked={paidLeads.every((pl) => filterLeadSource.includes(pl))} />
                      <ListItemText primary="Select All Paid Leads" />
                    </MenuItem>
                    {paidLeads.map((source) => (
                      <MenuItem key={source} value={source}>
                        <Checkbox checked={filterLeadSource.includes(source)} />
                        <ListItemText primary={source} />
                      </MenuItem>
                    ))}

                    {/* Group: Unpaid Leads */}
                    <ListSubheader>Unpaid Leads</ListSubheader>
                    {uniqueLeadSources
                      .filter((source) => !paidLeads.includes(source))
                      .map((source) => (
                        <MenuItem key={source} value={source}>
                          <Checkbox checked={filterLeadSource.includes(source)} />
                          <ListItemText primary={source} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                  <InputLabel id="owner-label">Owner</InputLabel>
                  <Select
                    labelId="owner-label"
                    name="owner"
                    value={filterOwner}
                    label="Owner"
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {uniqueOwners.map((own, idx) => (
                      <MenuItem key={idx} value={own}>
                        {own}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    labelId="state-label"
                    name="state"
                    value={filterState}
                    label="State"
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {uniqueStates.map((st, idx) => (
                      <MenuItem key={idx} value={st}>
                        {st}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                  <InputLabel id="granularity-label">Granularity</InputLabel>
                  <Select
                    labelId="granularity-label"
                    name="granularity"
                    value={granularity}
                    label="Granularity"
                    onChange={(e) => setGranularity(e.target.value)}
                  >
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Date Range Filters using DatePicker and shortcuts */}
              <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        textField={(params) => <TextField {...params} fullWidth />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        textField={(params) => <TextField {...params} fullWidth />}
                      />
                    </Grid>
                  </Grid>
                  
                </LocalizationProvider>
              </Grid>
              {/* <Box sx={{ mt: 2, ml: 5 }}> */}
              <Box sx={{ display: 'flex', gap: 1, ml: 2, mt: 1 }}>
                <Button
                    variant="outlined"
                    onClick={() => {
                    const now = new Date();
                    const start = new Date(now.getFullYear(), 0, 1);
                    const end = new Date(now.getFullYear(), 11, 31);
                    setStartDate(start);
                    setEndDate(end);
                    }}
                >
                    This Year
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        const now = new Date();
                        const start = new Date();
                        start.setDate(now.getDate() - 183);
                        setStartDate(start);
                        setEndDate(now);
                    }}
                >
                    Last 6 Months
                </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        const now = new Date();
                        const start = new Date();
                        start.setDate(now.getDate() - 365);
                        setStartDate(start);
                        setEndDate(now);
                      }}
                    >
                      Last 365 Days
                    </Button>
                    {/* <Button
                      variant="outlined"
                      onClick={() => {
                        setStartDate(null);
                        setEndDate(null);
                      }}
                    >
                      Clear
                    </Button> */}
                  {/* </Box> */}
                {/* <Button variant="contained" color="primary" onClick={() => applyFilters(leadStats)}>
                  Apply Filters
                </Button> */}
                <Button variant="outlined" color="secondary" onClick={() => {
                    resetFilters();
                    setStartDate(null);
                    setEndDate(null);
                }}>
                  Reset Filters
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Grid>
        {/* Chart Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            {chartData && chartData.labels && chartData.labels.length > 0 && !showLoading && (
              <Bar data={chartData} options={chartOptions} />
            )}
            {showLoading &&
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress color="success" size="3rem"/>
              </Box>
            }
          </Paper>
        </Grid>
        {/* Totals Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Sales Metrics
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Total Leads</Typography>
                  <Typography variant="h5">{totals.leads.toLocaleString()}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Total Projects</Typography>
                  <Typography variant="h5">{totals.projects.toLocaleString()}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Total Service Projects</Typography>
                  <Typography variant="h5">{totals.serviceProjects}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Total Solar Projects</Typography>
                  <Typography variant="h5">{totals.solarProjects.toLocaleString()}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Lead Cost</Typography>
                  <Typography variant="h5">${Math.round(totals.cost).toLocaleString()}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Total Project Value</Typography>
                  <Typography variant="h5">${Math.round(totals.projectValues).toLocaleString()}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Conversion Rate</Typography>
                  <Typography variant="h5">{totals.conversionRate.toFixed(1)}%</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Solar Inside Cost</Typography>
                  <Typography variant="h5">${Math.round(totals.segmentCost).toLocaleString()}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Lead + SIS Cost</Typography>
                  <Typography variant="h5">${Math.round(totals.combinedCost).toLocaleString()}</Typography>
                </Paper>
              </Grid>

              {/* <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Segment Leads</Typography>
                  <Typography variant="h5">{totals.segLeads}</Typography>
                </Paper>
              </Grid> */}
              {/* <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Sit Rate</Typography>
                  <Typography variant="h5">{totals.sitRate}%</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Total Solar Projects</Typography>
                  <Typography variant="h5">{totals.solarProjects.toLocaleString()}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Total Cost</Typography>
                  <Typography variant="h5">${Math.round(totals.cost).toLocaleString()}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="subtitle1">Total Project Value</Typography>
                  <Typography variant="h5">${Math.round(totals.projectValues).toLocaleString()}</Typography>
                </Paper>
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
