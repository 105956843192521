import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LunexHeaderLogo } from './NavbarElements';
import logo from '../../images/lunexcolorlogo.webp';
import useLogout from '../../hooks/useLogout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import useAuth from '../../hooks/useAuth';
import styled from 'styled-components';


const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const Navbar = ({ isNavbarCollapsed, toggleNavbar }) => {
  const navigate = useNavigate();
  const logout = useLogout();
  const { auth } = useAuth(); 

  const signOut = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: '#fff' }}>
      <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {/* Left: Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to='/'>
              <LunexHeaderLogo src={logo} alt='Lunex Logo Image' />
            </Link>
          </Box>

          <NavItems>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <Button
                component={Link}
                to="/"
                variant="contained"
                sx={{
                  color: 'primary',
                  textTransform: 'none',
                  fontWeight: 600
                }}
              >
                Home
              </Button>
          </Box>
          {/* Center: Admin Link (only if user has admin role) */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            {auth?.roles?.includes(5150) && (
              <Button
                component={Link}
                to="/admin"
                variant="contained"
                sx={{
                  color: 'primary',
                  textTransform: 'none',
                  fontWeight: 600
                }}
              >
                Admin
              </Button>
            )}
          </Box>
          </NavItems>

          {/* Right: Logout */}
          <Box>
            <Button
              onClick={signOut}
              variant="contained"
              color="secondary"
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
